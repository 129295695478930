<template>
    <div class="star-container">
      <keep-alive>
      <router-view></router-view>
      </keep-alive>
    </div>
</template>

<script>
export default {
  name: "star",
  components: {
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.star-container {
  width: 100%;

}
</style>
